import GUI from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import fireFlyVertexShader from './shaders/fireflies/vertex.glsl'
import fireFlyFragmentShader from './shaders/fireflies/fragment.glsl'
import portalVertexShader from './shaders/portal/vertex.glsl'
import portalFragmentShader from './shaders/portal/fragment.glsl'

/**
 * Base
 */
// Debug
const gui = new GUI({
    width: 400
})
gui.close();
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Object
 */
const bakedTexture = textureLoader.load('baked-fixed.jpg')
bakedTexture.flipY = false
bakedTexture.colorSpace = THREE.SRGBColorSpace

const bakedMaterial = new THREE.MeshBasicMaterial({map: bakedTexture})

const lightMaterial = new THREE.MeshBasicMaterial({color: 0xffffe5})


debugObject.portalColorStart = '#ffbdc5'
debugObject.portalColorEnd = '#fff6f8'




gui.addColor(debugObject,'portalColorStart').onChange(()=>{
    portalLightMaterial.uniforms.uColorStart.value.set(debugObject.portalColorStart)
})
gui.addColor(debugObject,'portalColorEnd').onChange(()=>{
    portalLightMaterial.uniforms.uColorEnd.value.set(debugObject.portalColorEnd)

})



const portalLightMaterial = new THREE.ShaderMaterial({
    vertexShader: portalVertexShader,
    fragmentShader: portalFragmentShader,
    uniforms: {
        uTime: {value: 0},
        uColorStart : { value: new THREE.Color(debugObject.portalColorStart)},
        uColorEnd : { value: new THREE.Color(debugObject.portalColorEnd)},

    }
})





gltfLoader.load('portal.glb',
    (gltf)=>{
        
        const bakedMesh = gltf.scene.children.find((child)=> child.name === 'baked')

        const poleLightAMesh = gltf.scene.children.find((child)=> child.name === 'poleLightA')
        const poleLightBMesh = gltf.scene.children.find((child)=> child.name === 'poleLightB')
        const portalLightMesh = gltf.scene.children.find((child)=> child.name === 'portalLight')

        bakedMesh.material = bakedMaterial
        poleLightAMesh.material = lightMaterial
        poleLightBMesh.material = lightMaterial
        portalLightMesh.material = portalLightMaterial

        scene.add(gltf.scene)
        console.log('loaded');
    })

const fireFliesGeometry = new THREE.BufferGeometry()
const fireFliesCount = 60;
const positionArray = new Float32Array(fireFliesCount*3)
const scalesArray = new Float32Array(fireFliesCount*1)


for (let i=0; i<fireFliesCount; i++) {
    const i3 = i*3
    positionArray[i3+0] = (Math.random()-0.5) * 4
    positionArray[i3+1] = (Math.random()) * 2
    positionArray[i3+2] = (Math.random()-0.5) * 4
    scalesArray[i] = Math.random();
}





fireFliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
fireFliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scalesArray, 1))


const fireFliesPoints = new THREE.Points(fireFliesGeometry,
    new THREE.ShaderMaterial({
        uniforms: {
            uPixelRatio: {value: Math.min(window.devicePixelRatio, 2)},
            uSize: {value: 100.0},
            uTime: {value: 0.0}
        },
        vertexShader:fireFlyVertexShader,
    fragmentShader: fireFlyFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
}))

gui.add(fireFliesPoints.material.uniforms.uSize,'value').min(40.0).max(500.0).step(1.0).name('Firefly Size')

scene.add(fireFliesPoints)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    fireFliesPoints.material.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2);
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 4
camera.position.y = 2
camera.position.z = 4
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.minPolarAngle = Math.PI / 16
controls.maxPolarAngle = Math.PI / 2  - Math.PI / 16
controls.minAzimuthAngle = 0
controls.maxAzimuthAngle = Math.PI/2

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
debugObject.clearColor = '#122507'
renderer.setClearColor(debugObject.clearColor)
gui.addColor(debugObject,'clearColor').onChange(()=>{
    renderer.setClearColor(debugObject.clearColor)

})
/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    fireFliesPoints.material.uniforms.uTime.value = elapsedTime;
    portalLightMaterial.uniforms.uTime.value = elapsedTime;


    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()